<script setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
const modalStore = useModalStore();
</script>

<template>
  <HeadlessTransitionRoot as="template" :show="!!modalStore.buffer[0]">
    <HeadlessDialog as="div" class="relative z-20" @close="modalStore.RESOLVE_MODAL(false, false, true)">
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel class="relative w-full max-w-max">
              <AtomButton
                aria-label="Close Modal"
                type="button"
                class="absolute right-6 top-6 z-[5] -m-1 p-1 dark:text-white"
                @click="modalStore.RESOLVE_MODAL(false, false, true)"
              >
                <XMarkIcon class="size-6" />
              </AtomButton>
              <component :is="modalStore.buffer[0]?.component" :props="modalStore.buffer[0]?.props" />
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>
